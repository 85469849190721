import React from 'react';
import Layouts from './Layouts/Layouts'

const App = () => {
  return (
    <>
      <div>
      </div>
      <div><Layouts
      /></div>
    </>
  )
}

export default App