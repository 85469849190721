import React, { useState } from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import MetaTitleDesc from '../MetaTitleDesc'
import { toast } from 'react-toastify';

const Space = () => {
  const [inputValue, setInputValue] = useState(' ');

  const handleCopy = () => {
    navigator.clipboard.writeText(inputValue);
    toast.success("SPACE Copied");
  };
  const Bookmark = () => {
    alert('Press Ctrl+D to bookmark this page.');
  }
  return (
    <div className="notranslate" translate="no">
      <MetaTitleDesc Translate="no" MetaTitle="Space Character Copy and Paste" MetaDesc="Space Character Copy and Paste" />
      <Container fluid>
        <div className="row">
          <div className="col-md-9">
            <div className='mb-4'>
              <p className="fs-17">
                Space is a special unicode character that exists between two words. You can generate it by pressing the "Spacebar" on your keyboard.
              </p>
            </div>
            <div className="text-center">
              <h4 className="fs-18 fw-700">Copy SPACE</h4>
              <p className="fs-17">You can copy space here and use it wherever you like</p>
              <input hidden type="text" value={inputValue} />
              <Button variant="secondary" onClick={handleCopy} className='mb-3 fs-18 theme-btn border-0' id="boton3"
                value="Copiar">
                Click Here to Copy Space ( )
              </Button>
            </div>
            <Row className="justify-content-center my-3">
              <Col md="8">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    {/* <thead className='table-dark'>
                  <tr>
                    <th>Unicode</th>
                    <th>Unicode Names</th>
                    <th>Copy</th>
                  </tr>
                </thead> */}
                    <tbody>
                      <tr>
                        <th>HTML Code</th>
                        <td> {"&#32;"} </td>
                      </tr>
                      <tr>
                        <th>CSS Code</th>
                        <td>\0020</td>
                      </tr>
                      <tr>
                        <th>Hex Code</th>
                        <td>{"&#x20;"}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </div>
          <div className="col-md-3">
            <button onClick={Bookmark} class="mb-3 w-100 d-block theme-btn-nobg btn ">Bookmark this Tool</button>
          </div>
        </div>

      </Container>
    </div>
  );
};

export default Space;
