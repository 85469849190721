import React, { useState } from 'react';
import { Container, Alert, Card, InputGroup, Form, Button, Row, Col, ListGroup } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import Whatsapp from '../assets/images/whatsapp.png';
import Discord from '../assets/images/discord.png';
import MetaTitleDesc from '../MetaTitleDesc';
import { FaPlus, FaMinus } from 'react-icons/fa';

const DE = () => {
  const [inputValue, setInputValue] = useState(1); // Initialize to 1 since 0 won't generate spaces
  const [generatedText, setGeneratedText] = useState('');

  const handleInputChange = (event) => {
    const newValue = parseInt(event.target.value);
    setInputValue(newValue);
  };

  // Function to generate blank spaces
  const generateCharacters = (newValue) => {
    const inputValueAsNumber = parseInt(newValue);
    if (!isNaN(inputValueAsNumber) && inputValueAsNumber >= 1 && inputValueAsNumber <= 10000) {
      const invisibleText = 'ㅤ'.repeat(inputValueAsNumber); // Zero Width Space character
      setGeneratedText(invisibleText);
    } else {
      alert('Ihr Text konnte nicht kopiert werden');
    }
  };

  // Function to handle the increment and decrement using FaPlus and FaMinus
  const handleIncrement = () => {
    const newValue = inputValue + 1;
    if (newValue <= 10000) {
      setInputValue(newValue);
      generateCharacters(newValue);
    }
  };

  const handleDecrement = () => {
    const newValue = inputValue - 1;
    if (newValue >= 1) {
      setInputValue(newValue);
      generateCharacters(newValue);
    }
  };

  const copyToClipboard = () => {
    const textarea = document.createElement('textarea');
    textarea.value = generatedText;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    toast.success('Ihr unsichtbarer Text wird kopiert');
  };

  const Bookmark = () => {
    alert('Drücken Sie Strg+D, um diese Seite mit einem Lesezeichen zu versehen.');
  }

  return (
    <div>
      <MetaTitleDesc MetaTitle="Unsichtbarer Text ( ) Unsichtbares Zeichen [ ] Leerer Text Kopieren und Einfügen" MetaDesc="Erzeuge unsichtbaren Text mit spezialisierten Unicode-Zeichen, perfekt für WhatsApp, Discord und mehr. Kopiere und füge nahtlos mit unserem kostenlosen und benutzerfreundlichen Tool ein!" />
      <Container fluid>
        <div className="row">
          <div className="col-md-9">
            <Alert style={{ background: "#664d03", border: "none" }}>
              <div className="text-center">
                <h1 className='text-white fw-700 fs-20 m-0'>INVISIBLE TEXT Copy and Paste</h1>
              </div>
            </Alert>
            <div class="mb-4">
              <p class="fs-17">Unsichtbarer Text, unsichtbare Buchstaben und unsichtbare Zeichen sind wie Leerzeichen auf deinem Bildschirm. Sie existieren, sind aber nicht sichtbar und werden oft verwendet, wenn normale Leerzeichen nicht funktionieren. Dies sind Unicode-Zeichen, auch bekannt als unsichtbare Symbole, leerer Text und Leerzeichen</p>
              <h4 class="fs-18 fw-700">Unsichtbarer Textgenerator</h4>
              <p class="fs-17">Unten ist unser unsichtbarer Textgenerator. Du kannst auswählen, wie viele Leerzeichen du erzeugen möchtest, sie dann kopieren und überall einfügen, wo du möchtest.</p>
              <Card className="my-3 theme-border theme-bg">
                <Card.Body className="text-center">
                  <Row className="justify-content-center">
                    <Col md="9">
                      <h3 style={{ color: "#664d03" }} className='mb-4 fw-700 fs-20 m-0'>INVISIBLE TEXT GENERATOR</h3>
                      <div className="row mb-3 p-2 bg-light align-items-center">
                        <div className="col-md-8">
                          <p className="fs-13 m-0">Schritt 1: Einfügen/Ändern der Menge an unsichtbarem Text.</p>
                        </div>
                        <div className="col-md-4">
                          <ListGroup className=' justify-content-between align-items-center' horizontal>
                            <ListGroup.Item className='border-0 p-0'>
                              <Button variant="secondary" className="theme-btn border-2 border-warning rounded-0 rounded-start btn btn-secondary" onClick={handleDecrement}>
                                <FaMinus />
                              </Button>
                            </ListGroup.Item>
                            <ListGroup.Item className='border-0 p-0'>
                              <Form.Control
                                type="number"
                                aria-label="invisibleText"
                                className="shadow-none fs-17"
                                aria-describedby="invisibleText"
                                min="1"
                                max="10000"
                                value={inputValue}
                                onChange={handleInputChange}
                              />
                            </ListGroup.Item>
                            <ListGroup.Item className='border-0 p-0'>
                              <Button variant="secondary" className="theme-btn border-2 border-warning rounded-0 rounded-end btn btn-secondary" onClick={handleIncrement}>
                                <FaPlus />
                              </Button>
                            </ListGroup.Item>
                          </ListGroup>
                        </div>
                      </div>

                      <div className="bg-light p-2">
                        <p className="fs-13 mb-3">Schritt 2: Klicken Sie auf Kopieren. ODER manuell auswählen und kopieren (Strg+C oder CMD+C).</p>
                        <Form.Group>
                          <Form.Control as="textarea" name="name"
                            id="texto2"
                            className='theme-border shadow-none mb-3'
                            value={generatedText}
                            readOnly />
                        </Form.Group>

                        <Button variant="secondary" className='mb-3 theme-btn border-0' id="boton3"
                          value="Copiar"
                          onClick={copyToClipboard}>
                          Kopie
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Card.Text>Um unsichtbaren Text mit Leerzeichen zu kopieren, klicken Sie auf die Schaltfläche „Kopieren“.</Card.Text>
                </Card.Body>
              </Card>
              <h4 class="fs-18 fw-700">Was ist unsichtbarer Text?</h4>
              <p class="fs-17">Unsichtbarer Text verwendet spezielle Unicode-Zeichen, die wie Leerzeichen aussehen. Diese Zeichen sind nützlich für:</p>
              <ul>
                <li>Versteckte Nachrichten senden</li>
                <li>Freunde veralbern</li>
                <li>Privatsphäre auf verschiedenen Plattformen wahren</li>
              </ul>
              <h4 class="fs-18 fw-700">Unsichtbaren Text kopieren</h4>
              <p class="fs-17">Um unsichtbaren Text zu kopieren, klicke auf die Schaltfläche Kopieren oder wähle den Text manuell aus und kopiere ihn. Dann kannst du ihn überall einfügen, wo du ihn benötigst.</p>
              <div class="justify-content-center my-3 row">
                <div class="col-md-8">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead class="table-dark">
                        <tr>
                          <th>Unicode</th>
                          <th>Unicode Names</th>
                          <th>Copy</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>U+0020</td>
                          <td><NavLink className="text-theme" to="/space/">Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+00A0</td>
                          <td><NavLink className="text-theme" to="/no-break-space/">No-Break Space</NavLink></td>
                          <td>[&nbsp;]</td>
                        </tr>
                        <tr>
                          <td>U+2000</td>
                          <td><NavLink className="text-theme" to="/en-quad/">En Quad</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2001</td>
                          <td><NavLink className="text-theme" to="/em-quad/">Em Quad</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2002</td>
                          <td><NavLink className="text-theme" to="/en-space/">En Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2003</td>
                          <td><NavLink className="text-theme" to="/em-space/">Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2004</td>
                          <td><NavLink className="text-theme" to="/three-per-em-space/">Three-Per-Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2005</td>
                          <td><NavLink className="text-theme" to="/four-per-em-space/">Four-Per-Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2006</td>
                          <td><NavLink className="text-theme" to="/six-per-em-space/">Six-Per-Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2007</td>
                          <td><NavLink className="text-theme" to="/figure-space/">Figure Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2008</td>
                          <td><NavLink className="text-theme" to="/punctuation-space/">Punctuation Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2009</td>
                          <td><NavLink className="text-theme" to="/thin-space/">Thin Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+200A</td>
                          <td><NavLink className="text-theme" to="/hair-space/">Hair Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2028</td>
                          <td><NavLink className="text-theme" to="/line-separator/">Line Separator</NavLink></td>
                          <td>[]</td>
                        </tr>
                        <tr>
                          <td>U+205F</td>
                          <td><NavLink className="text-theme" to="/medium-mathematical-space/">Medium Mathematical Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+3000</td>
                          <td><NavLink className="text-theme" to="/ideographic-space/">Ideographic Space</NavLink></td>
                          <td>[　]</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <h4 class="fs-18 fw-700">Wie benutzt man unsichtbaren Text?</h4>
              <h4 class="fs-18 fw-700">WhatsApp</h4>
              <div class="justify-content-center  row">
                <div class="col-md-8"><img src={Whatsapp} alt="" class="img-fluid mb-3"/></div>
              </div>
              <p class="fs-17">Sende leere Nachrichten, um deine Freunde zu überraschen. Es ist ein lustiger Trick, besonders an Tagen wie dem Aprilscherztag.</p>
              <h4 class="fs-18 fw-700">Discord</h4>
              <div class="justify-content-center  row">
                <div class="col-md-8"><img src={Discord} alt="" class="img-fluid mb-3"/></div>
              </div>
              <p class="fs-17">Verwende unsichtbaren Text, um anonym zu bleiben oder Freunde zu veralbern, ohne von den Administratoren bemerkt zu werden.</p>
              <h4 class="fs-18 fw-700">Free Fire</h4>
              <p class="fs-17">Erstelle einzigartige Namen mit unsichtbaren Leerzeichen, um im Spiel aufzufallen.</p>
              <h4 class="fs-18 fw-700">Steam</h4>
              <p class="fs-17">Bleibe unauffällig, indem du unsichtbare Zeichen in deinem Benutzernamen verwendest.</p>
              <h4 class="fs-18 fw-700">Fortnite</h4>
              <p class="fs-17">Verwende unsichtbare Zeichen, um deine Identität beim Spielen zu verbergen.</p>
              <h4 class="fs-18 fw-700">Twitter</h4>
              <p class="fs-17">Veröffentliche leere Tweets oder erstelle einzigartige Benutzernamen mit unsichtbarem Text.</p>
              <h4 class="fs-18 fw-700">YouTube</h4>
              <p class="fs-17">Verstecke deinen Markennamen oder füge unsichtbare Zeichen zu Titeln und Beschreibungen hinzu, um einen einzigartigen Touch zu verleihen.</p>
              <h4 class="fs-18 fw-700">Instagram</h4>
              <p class="fs-17">Sende leere Nachrichten und verbessere deine Beiträge mit unsichtbaren Texteffekten.</p>
              <h4 class="fs-18 fw-700">Roblox</h4>
              <p class="fs-17">Bleibe anonym, während du Inhalte postest, indem du unsichtbare Zeichen verwendest.</p>
              <h4 class="fs-18 fw-700">Wie funktioniert unsichtbarer Text?</h4>
              <p class="fs-17">Unsichtbarer Text besteht aus Unicode-Zeichen, die wie Leerzeichen aussehen. Auf iPhones kannst du die Funktion „Invisible Ink“ verwenden, um versteckte Nachrichten zu senden. Für andere Geräte verwende einen unsichtbaren Textgenerator, den du online findest.</p>
              <h4 class="fs-18 fw-700">Funktionen von unsichtbaren Textgeneratoren</h4>
              <ul>
                <li><strong>Einfach zu bedienen:  </strong> Kopiere und füge unsichtbaren Text mit nur wenigen Klicks ein.</li>
                <li><strong>Premium-Oberfläche:</strong> Benutzerfreundliches Design für einfache Navigation.</li>
                <li>
                  <strong>Mehrere Methoden:</strong>
                  <ul>
                    <li><strong>Methode 1:</strong> Klicke auf die Schaltfläche Kopieren, um den Text in deine Zwischenablage zu kopieren.</li>
                    <li><strong>Methode 2:</strong> Wähle den Text manuell aus, kopiere (Strg+C) und füge ihn ein.</li>
                  </ul>
                </li>
                <li><strong>Kostenlos:</strong> Keine Gebühren oder Kosten.</li>
                <li><strong>Mehrere Sprachen:</strong> Verfügbar in Englisch, Französisch, Portugiesisch und mehr.</li>
              </ul>
              <p class="fs-17">Unsichtbarer Text ermöglicht es dir, Nachrichten zu senden, die nur du und der Empfänger sehen können. Es bietet neue Kommunikationsmöglichkeiten und kann deine Sichtweise auf Online-Interaktionen verändern. Probiere es aus und entdecke die Möglichkeiten!</p>
            </div>
          </div>
          <div className="col-md-3">
            <button type="button" onClick={Bookmark} class="mb-3 w-100 d-block theme-btn-nobg btn ">Setzen Sie ein Lesezeichen für dieses Tool</button>
          </div>
        </div>
      </Container>



    </div>
  );
};

export default DE;
