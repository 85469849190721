import React, { useState } from 'react';
import { Container, Alert, Card, InputGroup, Form, Button, Row, Col, ListGroup } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import Whatsapp from '../assets/images/whatsapp.png';
import Discord from '../assets/images/discord.png';
import MetaTitleDesc from '../MetaTitleDesc';
import { FaPlus, FaMinus } from 'react-icons/fa';

const ES = () => {
  const [inputValue, setInputValue] = useState(1); // Initialize to 1 since 0 won't generate spaces
  const [generatedText, setGeneratedText] = useState('');

  const handleInputChange = (event) => {
    const newValue = parseInt(event.target.value);
    setInputValue(newValue);
  };

  // Function to generate blank spaces
  const generateCharacters = (newValue) => {
    const inputValueAsNumber = parseInt(newValue);
    if (!isNaN(inputValueAsNumber) && inputValueAsNumber >= 1 && inputValueAsNumber <= 10000) {
      const invisibleText = 'ㅤ'.repeat(inputValueAsNumber); // Zero Width Space character
      setGeneratedText(invisibleText);
    } else {
      alert('No se pudo copiar el texto');
    }
  };

  // Function to handle the increment and decrement using FaPlus and FaMinus
  const handleIncrement = () => {
    const newValue = inputValue + 1;
    if (newValue <= 10000) {
      setInputValue(newValue);
      generateCharacters(newValue);
    }
  };

  const handleDecrement = () => {
    const newValue = inputValue - 1;
    if (newValue >= 1) {
      setInputValue(newValue);
      generateCharacters(newValue);
    }
  };

  const copyToClipboard = () => {
    const textarea = document.createElement('textarea');
    textarea.value = generatedText;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    toast.success('Tu texto invisible está copiado');
  };

  const Bookmark = () => {
    alert('Presione Ctrl+D para marcar esta página.');
  }

  return (
    <div>
      <MetaTitleDesc MetaTitle="Texto Invisible ( ) Carácter Invisible [ ] Texto en Blanco Copiar y Pegar" MetaDesc="Genera texto invisible utilizando caracteres Unicode especializados, ideal para WhatsApp, Discord y más. ¡Copia y pega sin esfuerzo con nuestra herramienta gratuita y fácil de usar!" />
      <Container fluid>
        <div className="row">
          <div className="col-md-9">
            <Alert style={{ background: "#664d03", border: "none" }}>
              <div className="text-center">
                <h1 className='text-white fw-700 fs-20 m-0'>INVISIBLE TEXT Copy and Paste</h1>
              </div>
            </Alert>
            <div class="mb-4">
              <p class="fs-17">El texto invisible, las letras invisibles y los caracteres invisibles son como espacios en blanco en tu pantalla. Existen, pero no son visibles y a menudo se usan cuando los espacios regulares no funcionan. Estos son caracteres Unicode, también conocidos como símbolos invisibles, texto vacío y espacios en blanco.</p>
              <h4 class="fs-18 fw-700">Generador de Texto Invisible</h4>
              <p class="fs-17">A continuación se muestra nuestro generador de texto invisible. Puedes elegir cuántos caracteres en blanco deseas producir, luego copiarlos y pegarlos donde quieras.</p>
              <Card className="my-3 theme-border theme-bg">
                <Card.Body className="text-center">
                  <Row className="justify-content-center">
                    <Col md="9">
                      <h3 style={{ color: "#664d03" }} className='mb-4 fw-700 fs-20 m-0'>INVISIBLE TEXT GENERATOR</h3>
                      <div className="row mb-3 p-2 bg-light align-items-center">
                        <div className="col-md-8">
                          <p className="fs-13 m-0">Paso 1: Insertar/Cambiar la Cantidad de Texto Invisible.</p>
                        </div>
                        <div className="col-md-4">
                          <ListGroup className=' justify-content-between align-items-center' horizontal>
                            <ListGroup.Item className='border-0 p-0'>
                              <Button variant="secondary" className="theme-btn border-2 border-warning rounded-0 rounded-start btn btn-secondary" onClick={handleDecrement}>
                                <FaMinus />
                              </Button>
                            </ListGroup.Item>
                            <ListGroup.Item className='border-0 p-0'>
                              <Form.Control
                                type="number"
                                aria-label="invisibleText"
                                className="shadow-none fs-17"
                                aria-describedby="invisibleText"
                                min="1"
                                max="10000"
                                value={inputValue}
                                onChange={handleInputChange}
                              />
                            </ListGroup.Item>
                            <ListGroup.Item className='border-0 p-0'>
                              <Button variant="secondary" className="theme-btn border-2 border-warning rounded-0 rounded-end btn btn-secondary" onClick={handleIncrement}>
                                <FaPlus />
                              </Button>
                            </ListGroup.Item>
                          </ListGroup>
                        </div>
                      </div>

                      <div className="bg-light p-2">
                        <p className="fs-13 mb-3">Paso 2: haga clic en Copiar. O seleccione y copie manualmente (Ctrl+C o CMD+C).</p>
                        <Form.Group>
                          <Form.Control as="textarea" name="name"
                            id="texto2"
                            className='theme-border shadow-none mb-3'
                            value={generatedText}
                            readOnly />
                        </Form.Group>

                        <Button variant="secondary" className='mb-3 theme-btn border-0' id="boton3"
                          value="Copiar"
                          onClick={copyToClipboard}>
                          Copiar
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Card.Text>Para copiar texto invisible con espacios, haga clic en el botón Copiar.</Card.Text>
                </Card.Body>
              </Card>
              <h4 class="fs-18 fw-700">¿Qué es el texto invisible?</h4>
              <p class="fs-17">El texto invisible utiliza caracteres Unicode especiales que parecen espacios en blanco. Estos caracteres son útiles para:</p>
              <ul>
                <li>Enviar mensajes ocultos</li>
                <li>Bromear con amigos</li>
                <li>Mantener la privacidad en diferentes plataformas</li>
              </ul>
              <h4 class="fs-18 fw-700">Copiar texto invisible</h4>
              <p class="fs-17">Para copiar texto invisible, haz clic en el botón de Copiar o selecciona y copia el texto manualmente. Luego, puedes pegarlo donde lo necesites.</p>
              <div class="justify-content-center my-3 row">
                <div class="col-md-8">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead class="table-dark">
                        <tr>
                          <th>Unicode</th>
                          <th>Unicode Names</th>
                          <th>Copy</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>U+0020</td>
                          <td><NavLink className="text-theme" to="/space/">Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+00A0</td>
                          <td><NavLink className="text-theme" to="/no-break-space/">No-Break Space</NavLink></td>
                          <td>[&nbsp;]</td>
                        </tr>
                        <tr>
                          <td>U+2000</td>
                          <td><NavLink className="text-theme" to="/en-quad/">En Quad</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2001</td>
                          <td><NavLink className="text-theme" to="/em-quad/">Em Quad</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2002</td>
                          <td><NavLink className="text-theme" to="/en-space/">En Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2003</td>
                          <td><NavLink className="text-theme" to="/em-space/">Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2004</td>
                          <td><NavLink className="text-theme" to="/three-per-em-space/">Three-Per-Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2005</td>
                          <td><NavLink className="text-theme" to="/four-per-em-space/">Four-Per-Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2006</td>
                          <td><NavLink className="text-theme" to="/six-per-em-space/">Six-Per-Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2007</td>
                          <td><NavLink className="text-theme" to="/figure-space/">Figure Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2008</td>
                          <td><NavLink className="text-theme" to="/punctuation-space/">Punctuation Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2009</td>
                          <td><NavLink className="text-theme" to="/thin-space/">Thin Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+200A</td>
                          <td><NavLink className="text-theme" to="/hair-space/">Hair Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2028</td>
                          <td><NavLink className="text-theme" to="/line-separator/">Line Separator</NavLink></td>
                          <td>[]</td>
                        </tr>
                        <tr>
                          <td>U+205F</td>
                          <td><NavLink className="text-theme" to="/medium-mathematical-space/">Medium Mathematical Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+3000</td>
                          <td><NavLink className="text-theme" to="/ideographic-space/">Ideographic Space</NavLink></td>
                          <td>[　]</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <h4 class="fs-18 fw-700">Cómo usar el texto invisible</h4>
              <h4 class="fs-18 fw-700">WhatsApp</h4>
              <div class="justify-content-center  row">
                <div class="col-md-8"><img src={Whatsapp} alt="" class="img-fluid mb-3"/></div>
              </div>
              <p class="fs-17">Envía mensajes vacíos para sorprender a tus amigos. Es un truco divertido, especialmente en días como el Día de los Inocentes.</p>
              <h4 class="fs-18 fw-700">Discord</h4>
              <div class="justify-content-center  row">
                <div class="col-md-8"><img src={Discord} alt="" class="img-fluid mb-3"/></div>
              </div>
              <p class="fs-17">Usa texto invisible para mantener el anonimato o bromear con amigos sin ser notado por los administradores.</p>
              <h4 class="fs-18 fw-700">Free Fire</h4>
              <p class="fs-17">Crea nombres únicos con espacios invisibles para destacar en el juego.</p>
              <h4 class="fs-18 fw-700">Steam</h4>
              <p class="fs-17">Mantén un perfil bajo usando caracteres invisibles en tu nombre de usuario.</p>
              <h4 class="fs-18 fw-700">Fortnite</h4>
              <p class="fs-17">Usa caracteres invisibles para mantener tu identidad oculta mientras juegas.</p>
              <h4 class="fs-18 fw-700">Twitter</h4>
              <p class="fs-17">Publica tweets en blanco o crea nombres de usuario únicos con texto invisible.</p>
              <h4 class="fs-18 fw-700">YouTube</h4>
              <p class="fs-17">Oculta el nombre de tu marca o agrega caracteres invisibles a los títulos y descripciones para un toque único.</p>
              <h4 class="fs-18 fw-700">Instagram</h4>
              <p class="fs-17">Envía mensajes vacíos y mejora tus publicaciones con efectos de texto invisible.</p>
              <h4 class="fs-18 fw-700">Roblox</h4>
              <p class="fs-17">Mantén el anonimato al publicar contenido utilizando caracteres invisibles.</p>
              <h4 class="fs-18 fw-700">¿Cómo funciona el texto invisible?</h4>
              <p class="fs-17">El texto invisible está compuesto por caracteres Unicode que parecen espacios en blanco. En los iPhones, puedes usar la función de Tinta Invisible para enviar mensajes ocultos. Para otros dispositivos, usa un generador de texto invisible que puedes encontrar en línea.</p>
              <h4 class="fs-18 fw-700">Características de los generadores de texto invisible</h4>
              <ul>
                <li><strong>Fácil de usar:  </strong> Copia y pega texto invisible con solo unos pocos clics.</li>
                <li><strong>Interfaz premium:</strong> Diseño amigable para una fácil navegación.</li>
                <li>
                  <strong>Métodos múltiples:</strong>
                  <ul>
                    <li><strong>Método 1:</strong> Haz clic en el botón de copiar para copiar el texto en tu portapapeles.</li>
                    <li><strong>Método 2:</strong> Selecciona manualmente, copia (Ctrl+C) y pega el texto.</li>
                  </ul>
                </li>
                <li><strong>Gratuito:</strong> Sin cargos ni tarifas.</li>
                <li><strong>Múltiples idiomas:</strong> Disponible en inglés, francés, portugués y más.</li>
              </ul>
              <p class="fs-17">El texto invisible te permite enviar mensajes que solo tú y el destinatario pueden ver. Ofrece nuevas formas de comunicarte y puede cambiar la manera en que ves las interacciones en línea. ¡Pruébalo y descubre las posibilidades!</p>
            </div>
          </div>
          <div className="col-md-3">
            <button type="button" onClick={Bookmark} class="mb-3 w-100 d-block theme-btn-nobg btn ">Agregar esta herramienta a favoritos</button>
          </div>
        </div>
      </Container>



    </div>
  );
};

export default ES;
