import React from 'react'
import MetaTitleDesc from '../MetaTitleDesc'

const PrivacyPolicy = () => {
  return (

    <div>
      <MetaTitleDesc MetaTitle="Privacy Policy" MetaDesc="Privacy Policy" />
      <div class="container-fluid">
        <p class="fs-17">Welcome to Invisibletext.co. Your privacy is important to us. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website and use our services.</p>
        <h2 class="fs-18 fw-700">Information We Collect</h2>
        <p class="fs-17"> Personal Information When you visit our website or use our services, we may collect personal information such as your name, email address, and contact details. This information is collected only when voluntarily provided by you.</p>
        <h2 class="fs-18 fw-700">Usage Data</h2>
        <p class="fs-17">We may collect non-personal information about your interactions with our website, including IP addresses, browser type, pages visited, and other usage data to improve our services and user experience.</p>
        <h2 class="fs-18 fw-700">How We Use Your Information</h2>
        <p class="fs-17">We use the information we collect for the following purposes:</p>
        <ul>
          <li className='fs-17'>To provide and maintain our services, including customer support.</li>
          <li className='fs-17'>To improve, personalize, and optimize our website and services.</li>
          <li className='fs-17'>To communicate with you, including sending updates and promotional materials.</li>
          <li className='fs-17'>To comply with legal obligations and enforce our policies.</li>
        </ul>
        <h2 class="fs-18 fw-700">Sharing Your Information</h2>
        <p class="fs-17"> We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as described in this Privacy Policy or as required by law.</p>
        <h2 class="fs-18 fw-700">Security of Your Information</h2>
        <p class="fs-17"> We implement reasonable security measures to protect the confidentiality and integrity of your personal information. However, no method of transmission over the internet or electronic storage is completely secure.</p>
        <h2 class="fs-18 fw-700">Your Choices and Rights</h2>
        <p class="fs-17">You have the right to access, update, or delete your personal information. You may also opt-out of receiving promotional communications from us by following the instructions in the communication or contacting us directly.</p>
        <h2 class="fs-18 fw-700">Changes to This Privacy Policy</h2>
        <p class="fs-17">We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page with a revised effective date.</p>
        <h2 class="fs-18 fw-700">Contact Us</h2>
        <p class="fs-17"> If you have any questions about this Privacy Policy, please contact us at <a class="text-theme" href="mailto:invisibletextco@gmail.com">invisibletextco@gmail.com</a>.</p>
      </div>
    </div>
  )
}

export default PrivacyPolicy