import React, { useState } from 'react';
import { Container, Alert, Card, InputGroup, Form, Button, Row, Col, ListGroup } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import Whatsapp from '../assets/images/whatsapp.png';
import Discord from '../assets/images/discord.png';
import MetaTitleDesc from '../MetaTitleDesc';
import { FaPlus, FaMinus } from 'react-icons/fa';

const RU = () => {
  const [inputValue, setInputValue] = useState(1); // Initialize to 1 since 0 won't generate spaces
  const [generatedText, setGeneratedText] = useState('');

  const handleInputChange = (event) => {
    const newValue = parseInt(event.target.value);
    setInputValue(newValue);
  };

  // Function to generate blank spaces
  const generateCharacters = (newValue) => {
    const inputValueAsNumber = parseInt(newValue);
    if (!isNaN(inputValueAsNumber) && inputValueAsNumber >= 1 && inputValueAsNumber <= 10000) {
      const invisibleText = 'ㅤ'.repeat(inputValueAsNumber); // Zero Width Space character
      setGeneratedText(invisibleText);
    } else {
      alert('Не удалось скопировать текст');
    }
  };

  // Function to handle the increment and decrement using FaPlus and FaMinus
  const handleIncrement = () => {
    const newValue = inputValue + 1;
    if (newValue <= 10000) {
      setInputValue(newValue);
      generateCharacters(newValue);
    }
  };

  const handleDecrement = () => {
    const newValue = inputValue - 1;
    if (newValue >= 1) {
      setInputValue(newValue);
      generateCharacters(newValue);
    }
  };

  const copyToClipboard = () => {
    const textarea = document.createElement('textarea');
    textarea.value = generatedText;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    toast.success('Ваш невидимый текст скопирован');
  };

  const Bookmark = () => {
    alert('Нажмите Ctrl+D, чтобы добавить эту страницу в закладки.');
  }

  return (
    <div>
      <MetaTitleDesc MetaTitle="Невидимый текст ( ) Невидимый символ [ ] Пустой текст Копировать и Вставить" MetaDesc="Создавайте невидимый текст с помощью специализированных символов Unicode, идеально подходит для WhatsApp, Discord и других приложений. Легко копируйте и вставляйте с помощью нашего бесплатного и удобного инструмента!" />
      <Container fluid>
        <div className="row">
          <div className="col-md-9">
            <Alert style={{ background: "#664d03", border: "none" }}>
              <div className="text-center">
                <h1 className='text-white fw-700 fs-20 m-0'>INVISIBLE TEXT Copy and Paste</h1>
              </div>
            </Alert>
            <div class="mb-4">
              <p class="fs-17">Невидимый текст, невидимые буквы и невидимые символы похожи на пустые пробелы на вашем экране. Они существуют, но не видны и часто используются, когда обычные пробелы не работают. Это символы Unicode, также известные как невидимые символы, пустой текст и пробелы.</p>
              <h4 class="fs-18 fw-700">Генератор Невидимого Текста</h4>
              <p class="fs-17">Ниже представлен наш генератор невидимого текста. Вы можете выбрать, сколько пустых символов хотите создать, затем скопировать их и вставить куда угодно.</p>
              <Card className="my-3 theme-border theme-bg">
                <Card.Body className="text-center">
                  <Row className="justify-content-center">
                    <Col md="9">
                      <h3 style={{ color: "#664d03" }} className='mb-4 fw-700 fs-20 m-0'>INVISIBLE TEXT GENERATOR</h3>
                      <div className="row mb-3 p-2 bg-light align-items-center">
                        <div className="col-md-8">
                          <p className="fs-13 m-0">Шаг 1. Вставьте/измените количество невидимого текста.</p>
                        </div>
                        <div className="col-md-4">
                          <ListGroup className=' justify-content-between align-items-center' horizontal>
                            <ListGroup.Item className='border-0 p-0'>
                              <Button variant="secondary" className="theme-btn border-2 border-warning rounded-0 rounded-start btn btn-secondary" onClick={handleDecrement}>
                                <FaMinus />
                              </Button>
                            </ListGroup.Item>
                            <ListGroup.Item className='border-0 p-0'>
                              <Form.Control
                                type="number"
                                aria-label="invisibleText"
                                className="shadow-none fs-17"
                                aria-describedby="invisibleText"
                                min="1"
                                max="10000"
                                value={inputValue}
                                onChange={handleInputChange}
                              />
                            </ListGroup.Item>
                            <ListGroup.Item className='border-0 p-0'>
                              <Button variant="secondary" className="theme-btn border-2 border-warning rounded-0 rounded-end btn btn-secondary" onClick={handleIncrement}>
                                <FaPlus />
                              </Button>
                            </ListGroup.Item>
                          </ListGroup>
                        </div>
                      </div>

                      <div className="bg-light p-2">
                        <p className="fs-13 mb-3">Шаг 2: Нажмите «Копировать». Или выберите и скопируйте вручную (Ctrl+C или CMD+C).</p>
                        <Form.Group>
                          <Form.Control as="textarea" name="name"
                            id="texto2"
                            className='theme-border shadow-none mb-3'
                            value={generatedText}
                            readOnly />
                        </Form.Group>

                        <Button variant="secondary" className='mb-3 theme-btn border-0' id="boton3"
                          value="Copiar"
                          onClick={copyToClipboard}>
                          Копировать
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Card.Text>Чтобы скопировать невидимый текст с пробелами, нажмите кнопку «Копировать».</Card.Text>
                </Card.Body>
              </Card>
              <h4 class="fs-18 fw-700">Что такое невидимый текст?</h4>
              <p class="fs-17">Невидимый текст использует специальные символы Unicode, которые выглядят как пустые пробелы. Эти символы полезны для:</p>
              <ul>
                <li>Отправки скрытых сообщений</li>
                <li>Розыгрышей друзей</li>
                <li>Сохранения конфиденциальности на различных платформах</li>
              </ul>
              <h4 class="fs-18 fw-700">Копирование невидимого текста</h4>
              <p class="fs-17">Чтобы скопировать невидимый текст, нажмите кнопку "Копировать" или выберите и скопируйте текст вручную. Затем вы можете вставить его туда, где он вам нужен.</p>
              <div class="justify-content-center my-3 row">
                <div class="col-md-8">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead class="table-dark">
                        <tr>
                          <th>Unicode</th>
                          <th>Unicode Names</th>
                          <th>Copy</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>U+0020</td>
                          <td><NavLink className="text-theme" to="/space/">Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+00A0</td>
                          <td><NavLink className="text-theme" to="/no-break-space/">No-Break Space</NavLink></td>
                          <td>[&nbsp;]</td>
                        </tr>
                        <tr>
                          <td>U+2000</td>
                          <td><NavLink className="text-theme" to="/en-quad/">En Quad</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2001</td>
                          <td><NavLink className="text-theme" to="/em-quad/">Em Quad</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2002</td>
                          <td><NavLink className="text-theme" to="/en-space/">En Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2003</td>
                          <td><NavLink className="text-theme" to="/em-space/">Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2004</td>
                          <td><NavLink className="text-theme" to="/three-per-em-space/">Three-Per-Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2005</td>
                          <td><NavLink className="text-theme" to="/four-per-em-space/">Four-Per-Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2006</td>
                          <td><NavLink className="text-theme" to="/six-per-em-space/">Six-Per-Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2007</td>
                          <td><NavLink className="text-theme" to="/figure-space/">Figure Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2008</td>
                          <td><NavLink className="text-theme" to="/punctuation-space/">Punctuation Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2009</td>
                          <td><NavLink className="text-theme" to="/thin-space/">Thin Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+200A</td>
                          <td><NavLink className="text-theme" to="/hair-space/">Hair Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2028</td>
                          <td><NavLink className="text-theme" to="/line-separator/">Line Separator</NavLink></td>
                          <td>[]</td>
                        </tr>
                        <tr>
                          <td>U+205F</td>
                          <td><NavLink className="text-theme" to="/medium-mathematical-space/">Medium Mathematical Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+3000</td>
                          <td><NavLink className="text-theme" to="/ideographic-space/">Ideographic Space</NavLink></td>
                          <td>[　]</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <h4 class="fs-18 fw-700">Как использовать невидимый текст?</h4>
              <h4 class="fs-18 fw-700">WhatsApp</h4>
              <div class="justify-content-center  row">
                <div class="col-md-8"><img src={Whatsapp} alt="" class="img-fluid mb-3" /></div>
              </div>
              <p class="fs-17">Отправляйте пустые сообщения, чтобы удивить своих друзей. Это забавный трюк, особенно в такие дни, как День дураков.</p>
              <h4 class="fs-18 fw-700">Discord</h4>
              <div class="justify-content-center  row">
                <div class="col-md-8"><img src={Discord} alt="" class="img-fluid mb-3" /></div>
              </div>
              <p class="fs-17">Используйте невидимый текст, чтобы оставаться анонимным или разыгрывать друзей, не привлекая внимания администраторов</p>
              <h4 class="fs-18 fw-700">Free Fire</h4>
              <p class="fs-17">Создавайте уникальные имена с невидимыми пробелами, чтобы выделиться в игре.</p>
              <h4 class="fs-18 fw-700">Steam</h4>
              <p class="fs-17">Оставайтесь незаметным, используя невидимые символы в вашем имени пользователя.</p>
              <h4 class="fs-18 fw-700">Fortnite</h4>
              <p class="fs-17">Используйте невидимые символы, чтобы скрыть свою личность во время игры.</p>
              <h4 class="fs-18 fw-700">Twitter</h4>
              <p class="fs-17">Публикуйте пустые твиты или создавайте уникальные имена пользователей с невидимым текстом.</p>
              <h4 class="fs-18 fw-700">YouTube</h4>
              <p class="fs-17">Скрывайте название своего бренда или добавляйте невидимые символы в заголовки и описания для уникальности.</p>
              <h4 class="fs-18 fw-700">Instagram</h4>
              <p class="fs-17">Отправляйте пустые сообщения и улучшайте свои публикации с помощью эффектов невидимого текста.</p>
              <h4 class="fs-18 fw-700">Roblox</h4>
              <p class="fs-17">Оставайтесь анонимным, публикуя контент, используя невидимые символы.</p>
              <h4 class="fs-18 fw-700">Как работает невидимый текст?</h4>
              <p class="fs-17">Невидимый текст состоит из символов Unicode, которые выглядят как пустые пробелы. На iPhone вы можете использовать функцию "Невидимые чернила" для отправки скрытых сообщений. Для других устройств используйте генератор невидимого текста, который можно найти онлайн.</p>
              <h4 class="fs-18 fw-700">Особенности генераторов невидимого текста</h4>
              <ul>
                <li><strong>Легко использовать:  </strong> Копируйте и вставляйте невидимый текст всего за несколько кликов.</li>
                <li><strong>Премиум интерфейс:</strong> Удобный дизайн для легкой навигации.</li>
                <li>
                  <strong>Множество методов:</strong>
                  <ul>
                    <li><strong>Метод 1:</strong> Нажмите кнопку копирования, чтобы скопировать текст в буфер обмена.</li>
                    <li><strong>Метод 2:</strong> Выберите текст вручную, скопируйте (Ctrl+C) и вставьте его.</li>
                  </ul>
                </li>
                <li><strong>Бесплатно:</strong> Без платы и сборов.</li>
                <li><strong>Несколько языков:</strong> Доступно на английском, французском, португальском и других языках.</li>
              </ul>
              <p class="fs-17">Невидимый текст позволяет вам отправлять сообщения, которые видите только вы и получатель. Он предлагает новые способы общения и может изменить ваше восприятие онлайн-взаимодействий. Попробуйте и откройте для себя новые возможности!</p>
            </div>
          </div>
          <div className="col-md-3">
            <button type="button" onClick={Bookmark} class="mb-3 w-100 d-block theme-btn-nobg btn ">Добавьте этот инструмент в закладки</button>
          </div>
        </div>
      </Container>


    </div>
  );
};

export default RU;
