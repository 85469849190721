import React, { useState } from 'react';
import { Container, Alert, Card, InputGroup, Form, Button, Row, Col, ListGroup } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import Whatsapp from '../assets/images/whatsapp.png';
import Discord from '../assets/images/discord.png';
import MetaTitleDesc from '../MetaTitleDesc';
import { FaPlus, FaMinus } from 'react-icons/fa';

const Home = () => {
  const [inputValue, setInputValue] = useState(1); // Initialize to 1 since 0 won't generate spaces
  const [generatedText, setGeneratedText] = useState('');

  const handleInputChange = (event) => {
    const newValue = parseInt(event.target.value);
    setInputValue(newValue);
  };

  // Function to generate blank spaces
  const generateCharacters = (newValue) => {
    const inputValueAsNumber = parseInt(newValue);
    if (!isNaN(inputValueAsNumber) && inputValueAsNumber >= 1 && inputValueAsNumber <= 10000) {
      const invisibleText = 'ㅤ'.repeat(inputValueAsNumber); // Zero Width Space character
      setGeneratedText(invisibleText);
    } else {
      alert('Failed to Copy your text');
    }
  };

  // Function to handle the increment and decrement using FaPlus and FaMinus
  const handleIncrement = () => {
    const newValue = inputValue + 1;
    if (newValue <= 10000) {
      setInputValue(newValue);
      generateCharacters(newValue);
    }
  };

  const handleDecrement = () => {
    const newValue = inputValue - 1;
    if (newValue >= 1) {
      setInputValue(newValue);
      generateCharacters(newValue);
    }
  };

  const copyToClipboard = () => {
    const textarea = document.createElement('textarea');
    textarea.value = generatedText;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    toast.success('Your Invisible Text is Copied');
  };

  const Bookmark = () => {
    alert('Press Ctrl+D to bookmark this page.');
  }

  return (
    <div>
      <MetaTitleDesc MetaTitle="Invisible Text ( ) Invisible Character [ ] Blank Text Copy and Paste" MetaDesc="Create invisible text using specialized Unicode characters, perfect for WhatsApp, Discord, and more. Copy and paste seamlessly with our free, user-friendly tool!" />
      <Container fluid>
        <div className="row">
          <div className="col-md-9">
            <Alert style={{ background: "#664d03", border: "none" }}>
              <div className="text-center">
                <h1 className='text-white fw-700 fs-20 m-0'>INVISIBLE TEXT Copy and Paste</h1>
              </div>
            </Alert>
            <div class="mb-4">
              <p class="fs-17">
                <strong>Invisible text, invisible letters,</strong> and <strong>invisible characters</strong> are like blank spaces on your screen. They exist but are not visible, often used when regular spaces won't work. These are <strong>Unicode characters</strong>, also known as <strong>invisible symbols, empty text,</strong> and <strong>white space</strong>.
              </p>
              <h4 class="fs-18 fw-700">Invisible Text Generator</h4>
              <p class="fs-17">Below is our invisible text generator. You can choose how many blank characters you want to produce, then copy them and paste them wherever you want.</p>
              <Card className="my-3 theme-border theme-bg">
                <Card.Body className="text-center">
                  <Row className="justify-content-center">
                    <Col md="9">
                      <h3 style={{ color: "#664d03" }} className='mb-4 fw-700 fs-20 m-0'>INVISIBLE TEXT GENERATOR</h3>
                      <div className="row mb-3 p-2 bg-light align-items-center">
                        <div className="col-md-8">
                          <p className="fs-13 m-0">Step 1: Insert / Modify the quantity of invisible text.</p>
                        </div>
                        <div className="col-md-4">
                          <ListGroup className=' justify-content-between align-items-center' horizontal>
                            <ListGroup.Item className='border-0 p-0'>
                              <Button variant="secondary" className="theme-btn border-2 border-warning rounded-0 rounded-start btn btn-secondary" onClick={handleDecrement}>
                                <FaMinus />
                              </Button>
                            </ListGroup.Item>
                            <ListGroup.Item className='border-0 p-0'>
                              <Form.Control
                                type="number"
                                aria-label="invisibleText"
                                className="shadow-none fs-17"
                                aria-describedby="invisibleText"
                                min="1"
                                max="10000"
                                value={inputValue}
                                onChange={handleInputChange}
                              />
                            </ListGroup.Item>
                            <ListGroup.Item className='border-0 p-0'>
                              <Button variant="secondary" className="theme-btn border-2 border-warning rounded-0 rounded-end btn btn-secondary" onClick={handleIncrement}>
                                <FaPlus />
                              </Button>
                            </ListGroup.Item>
                          </ListGroup>
                        </div>
                      </div>

                      <div className="bg-light p-2">
                        <p className="fs-13 mb-3">Step 2: Click Copy. OR manually select and copy (Ctrl+C or CMD+C).</p>
                        <Form.Group>
                          <Form.Control as="textarea" name="name"
                            id="texto2"
                            className='theme-border shadow-none mb-3'
                            value={generatedText}
                            readOnly />
                        </Form.Group>

                        <Button variant="secondary" className='mb-3 theme-btn border-0' id="boton3"
                          value="Copiar"
                          onClick={copyToClipboard}>
                          Copy
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Card.Text>To copy invisible text with spaces, click the Copy button.</Card.Text>
                </Card.Body>
              </Card>
              <h4 class="fs-18 fw-700">What Is Invisible Text?</h4>
              <p class="fs-17"><strong>Invisible text </strong> uses special <strong>Unicode characters</strong> that look like blank spaces. These characters are useful for:</p>
              <ul>
                <li>Sending hidden messages</li>
                <li>Pranking friends</li>
                <li>Maintaining privacy on different platforms</li>
              </ul>
              <h4 class="fs-18 fw-700">Copying Invisible Text</h4>
              <p class="fs-17">To copy <strong>invisible text</strong>, click the <strong>Copy button</strong> or manually select and copy the text. You can then paste it anywhere you need.</p>
              <div class="justify-content-center my-3 row">
                <div class="col-md-8">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead class="table-dark">
                        <tr>
                          <th>Unicode</th>
                          <th>Unicode Names</th>
                          <th>Copy</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>U+0020</td>
                          <td><NavLink className="text-theme" to="/space/">Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+00A0</td>
                          <td><NavLink className="text-theme" to="/no-break-space/">No-Break Space</NavLink></td>
                          <td>[&nbsp;]</td>
                        </tr>
                        <tr>
                          <td>U+2000</td>
                          <td><NavLink className="text-theme" to="/en-quad/">En Quad</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2001</td>
                          <td><NavLink className="text-theme" to="/em-quad/">Em Quad</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2002</td>
                          <td><NavLink className="text-theme" to="/en-space/">En Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2003</td>
                          <td><NavLink className="text-theme" to="/em-space/">Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2004</td>
                          <td><NavLink className="text-theme" to="/three-per-em-space/">Three-Per-Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2005</td>
                          <td><NavLink className="text-theme" to="/four-per-em-space/">Four-Per-Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2006</td>
                          <td><NavLink className="text-theme" to="/six-per-em-space/">Six-Per-Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2007</td>
                          <td><NavLink className="text-theme" to="/figure-space/">Figure Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2008</td>
                          <td><NavLink className="text-theme" to="/punctuation-space/">Punctuation Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2009</td>
                          <td><NavLink className="text-theme" to="/thin-space/">Thin Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+200A</td>
                          <td><NavLink className="text-theme" to="/hair-space/">Hair Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2028</td>
                          <td><NavLink className="text-theme" to="/line-separator/">Line Separator</NavLink></td>
                          <td>[]</td>
                        </tr>
                        <tr>
                          <td>U+205F</td>
                          <td><NavLink className="text-theme" to="/medium-mathematical-space/">Medium Mathematical Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+3000</td>
                          <td><NavLink className="text-theme" to="/ideographic-space/">Ideographic Space</NavLink></td>
                          <td>[　]</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <h4 class="fs-18 fw-700">How to Use Invisible Text?</h4>
              <h4 class="fs-18 fw-700">WhatsApp</h4>
              <div class="justify-content-center  row">
                <div class="col-md-8"><img src={Whatsapp} alt="" class="img-fluid mb-3" /></div>
              </div>
              <p class="fs-17">Send empty messages to surprise friends. It's a fun trick, especially on days like <strong>April Fool's</strong>.</p>
              <h4 class="fs-18 fw-700">Discord</h4>
              <div class="justify-content-center  row">
                <div class="col-md-8"><img src={Discord} alt="" class="img-fluid mb-3" /></div>
              </div>
              <p class="fs-17">Use <strong>invisible text</strong> to stay anonymous or prank friends without getting noticed by  <strong>admins</strong>.</p>
              <h4 class="fs-18 fw-700">Free Fire</h4>
              <p class="fs-17">Create unique names with <strong>invisible spaces</strong> to stand out in the game.</p>
              <h4 class="fs-18 fw-700">Steam</h4>
              <p class="fs-17">Keep a low profile by using <strong>invisible characters</strong> in your username.</p>
              <h4 class="fs-18 fw-700">Fortnite</h4>
              <p class="fs-17">Use <strong>invisible characters</strong> to keep your identity hidden while playing.</p>
              <h4 class="fs-18 fw-700">Twitter</h4>
              <p class="fs-17">Post blank tweets or create unique usernames with <strong>invisible text.</strong></p>
              <h4 class="fs-18 fw-700">YouTube</h4>
              <p class="fs-17">Hide your <strong>brand name</strong> or add <strong>invisible characters</strong> to titles and descriptions for a unique touch.</p>
              <h4 class="fs-18 fw-700">Instagram</h4>
              <p class="fs-17">Send blank messages and enhance your posts with <strong>invisible text</strong> effects.</p>
              <h4 class="fs-18 fw-700">Roblox</h4>
              <p class="fs-17">Stay anonymous while posting content by using <strong>invisible characters.</strong></p>
              <h4 class="fs-18 fw-700">How Does Invisible Text Work?</h4>
              <p class="fs-17"><strong>Invisible text</strong> is made up of <strong>Unicode characters</strong> that look like blank spaces. On <strong>iPhones</strong>, you can use the <strong>Invisible Ink</strong> feature to send hidden messages. For other devices, use an <strong>invisible text generator</strong> found online.</p>
              <h4 class="fs-18 fw-700">Features of Invisible Text Generators</h4>
              <ul>
                <li><strong>Easy to Use:  </strong> Copy and paste <strong>invisible text</strong> with just a few clicks.</li>
                <li><strong>Premium Interface:</strong> User-friendly design for easy navigation.</li>
                <li>
                  <strong>Multiple Methods:</strong>
                  <ul>
                    <li><strong>Method 1:</strong> Click the <strong>copy button</strong> to copy text to your clipboard.</li>
                    <li><strong>Method 2:</strong> Manually select, copy (Ctrl+C), and paste the text.</li>
                  </ul>
                </li>
                <li><strong>Free to Use:</strong> No charges or fees.</li>
                <li><strong>Multiple Languages:</strong> Available in <strong>English, French, Portuguese,</strong> and more.</li>
              </ul>
              <p class="fs-17"><strong>Invisible text</strong> allows you to send messages that only you and the recipient can see. It offers new ways to communicate and can change how you view online interactions. Try it out and see the possibilities!</p>
            </div>
          </div>
          <div className="col-md-3">
            <button onClick={Bookmark} class="mb-3 w-100 d-block theme-btn-nobg btn ">Bookmark this Tool</button>
          </div>
        </div>
      </Container>



    </div>
  );
};

export default Home;
