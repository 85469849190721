import React, { useState } from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import MetaTitleDesc from '../MetaTitleDesc'
import { toast } from 'react-toastify';

const EmSpace = () => {
  const [inputValue, setInputValue] = useState(' ');

  const handleCopy = () => {
    navigator.clipboard.writeText(inputValue);
    toast.success("Em Space Copied");
  };

  const Bookmark = () => {
    alert('Press Ctrl+D to bookmark this page.');
  }
  return (
    <div className="notranslate" translate="no">
      <MetaTitleDesc Translate="no" MetaTitle="Em Space (U+2003) Character Copy and Paste" MetaDesc="Em Space (U+2003) Character Copy and Paste" />
      <Container fluid>
        <div className="row">
          <div className="col-md-9">
            <div className='mb-4'>
              <p className="fs-17">
                The Unicode character U+2003 represents the "Em Space," which is a whitespace character equal to the width of the em unit in typography.
              </p>
            </div>
            <div className="text-center">
              <h4 className="fs-18 fw-700">Copy Em Space</h4>
              <p className="fs-17">You can copy Em Space here and use it wherever you like</p>
              <input hidden type="text" value={inputValue} />
              <Button variant="secondary" onClick={handleCopy} className='mb-3 fs-18 theme-btn border-0' id="boton3"
                value="Copiar">
                Click Here to Copy Em Space (ㅤ)
              </Button>
            </div>
            <Row className="justify-content-center my-3">
              <Col md="8">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    {/* <thead className='table-dark'>
                  <tr>
                    <th>Unicode</th>
                    <th>Unicode Names</th>
                    <th>Copy</th>
                  </tr>
                </thead> */}
                    <tbody>
                      <tr>
                        <th>Unicode</th>
                        <td> U+2003 </td>
                      </tr>
                      <tr>
                        <th>HTML Entity</th>
                        <td>{"&emsp;"}</td>
                      </tr>
                      <tr>
                        <th>LateX</th>
                        <td>\quad</td>
                      </tr>
                      <tr>
                        <th>CSS</th>
                        <td>\2003</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </div>
          <div className="col-md-3">
            <button onClick={Bookmark} class="mb-3 w-100 d-block theme-btn-nobg btn ">Bookmark this Tool</button>
          </div>
        </div>

      </Container>
    </div>
  );
};

export default EmSpace;
