import React, { useState } from 'react';
import { Container, Alert, Card, InputGroup, Form, Button, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import Whatsapp from '../assets/images/whatsapp.png';
import Discord from '../assets/images/discord.png';
import MetaTitleDesc from '../MetaTitleDesc';

const ReverseText = () => {

  const [inputText, setInputText] = useState('');
  const [reversedText, setReversedText] = useState('');

  // Function to handle input change
  const handleInputChange = (e) => {
    const text = e.target.value;
    setInputText(text);

    // Split the text into words and reverse the word order
    const reversed = text.split(' ').reverse().join(' ');
    setReversedText(reversed);
  };

  // Function to copy the reversed text to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(reversedText);
    toast.success('Text copied to clipboard!');
  };

  const Bookmark = () => {
    alert('Press Ctrl+D to bookmark this page.');
  }

  return (
    <div>
      <MetaTitleDesc MetaTitle="Reverse Text Generator" MetaDesc="Reverse Text Generator" />
      <Container fluid>
        <div className="row">
          <div className="col-md-9">
            <Alert style={{ background: "#664d03", border: "none" }}>
              <div className="text-center">
                <h1 className='text-white fw-700 fs-20 m-0'>Reverse Text Generator</h1>
              </div>
            </Alert>
            <div className='mb-4'>
              <p className="fs-17">
                <strong>Reverse text generator</strong> is used to reverse words, spellings, letters, and sentences. It's actually a <strong>backwards text generator</strong> tool.
              </p>
              <Card className="my-3 theme-border theme-bg">
                <Card.Body className="text-center">
                  <Row className="justify-content-center">
                    <Col md="8">
                      <div className="bg-light p-2">
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="text"
                            aria-label="invisibleText"
                            className="shadow-none border-black fs-17"
                            aria-describedby="invisibleText"
                            min="1"
                            max="10000"
                            onChange={handleInputChange} // Call the input change handler
                          />
                        </InputGroup>

                        <Form.Group>
                          <Form.Control
                            as="textarea"
                            name="name"
                            id="texto2"
                            className="theme-border shadow-none mb-3"
                            value={reversedText} // Display reversed text
                            readOnly
                          />
                        </Form.Group>

                        <Button
                          variant="secondary"
                          className="mb-3 theme-btn border-0"
                          id="boton3"
                          onClick={copyToClipboard} // Copy reversed text to clipboard
                        >
                          Copy
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <h2 className="fs-20 fw-700">Why Would You Want to Reverse Text?</h2>
              <h4 className="fs-18 fw-700">Time Wasting Fun</h4>
              <p className="fs-17">At some point in your life, you may find that you have too much time on your hands. 9 out of 10 leading experts agree that reversing text is a proven way to waste time.</p>

              <h4 className="fs-18 fw-700">Funny or Cool Names</h4>
              <p className="fs-17">Your name, when spelled in reverse letters, may be something funny or cool (e.g., <strong>Liam</strong> reversed spells <strong>Mail</strong>). Avoid the surprise of getting embarrassed at some point in the future by finding out now. Translate it using our backwards text generator.</p>

            </div>
            <div>
              <h4 className="fs-18 fw-700">Confuse Your Boss</h4>

              <p className="fs-17">Send an email about a project you are working on completely in reverse. When your boss asks about the problem with your email, tell him that he must be having computer problems. Make sure to test it with an online notepad before you send it.</p>
              <h4 className="fs-18 fw-700">Create Funny Words </h4>

              <p className="fs-17">
                Try to find a word, or combination of words, that when reversed spell something funny or strange (e.g., 'knits i', 'god a ward', 'bats', 'desserts', 'and', 'live', 'evian', and 'star'). If you find another one, send it to us and we'll post it here.
              </p>

              <h4 className="fs-18 fw-700">Palindrome Fun</h4>
              <p className="fs-17">Try to find words that are exactly the same when reversed. For example, 'racecar' when reversed is also 'racecar' when spelled backwards.</p>
              <h4 className="fs-18 fw-700">Secret Messages</h4>
              <p className="fs-17">Send the “I love you” message in reverse to your friends, i.e., “uoy evol I” to make your messages more interesting. </p>
              <h4 className="fs-18 fw-700">Examples of Backwards Text</h4>
              <p className="fs-17">Here are some phrases and names that are fun to reverse:</p>
              <ul>
                <li className="fs-17">was it a car or a cat i saw</li>
                <li className="fs-17">able was i ere i saw elba</li>
                <li className="fs-17">a man a plan a canal panama</li>
                <li className="fs-17">may a moody baby doom a yam</li>
                <li className="fs-17">never odd or even</li>
                <li className="fs-17">"Rats gnash teeth," sang Star</li>
                <li className="fs-17">madam I'm Adam</li>
                <li className="fs-17">go hang a salami im a lasagna hog</li>
                <li className="fs-17">rats live on no evil star</li>
                <li className="fs-17">deny a pioneer free beer? free? no i pay ned</li>
                <li className="fs-17">sun, ever a rose so rare - venus</li>
                <li className="fs-17">no i no onion</li>
                <li className="fs-17">mr owl ate my metal worm</li>
                <li className="fs-17">Names like 'Bob', 'Anna' and 'Hannah'.</li>
                <li className="fs-17">a nut for a jar of tuna</li>
                <li className="fs-17">do geese see god?</li>
              </ul>

              <h2 className="fs-20 fw-700">Practical Uses for Reverse Text</h2>
              <h4 className="fs-18 fw-700">Hebrew Text</h4>
              <p className="fs-17">Save time by taking Hebrew text that was keyed right-to-left and convert it to a left-to-right format.</p>

              <h4 className="fs-18 fw-700">Chess FEN Notation</h4>
              <p className="fs-17">If you play chess and use the FEN notation to describe the placement of pieces on a chessboard, you can reverse the FEN string to see how the board looks from the other player's perspective.</p>

              <h4 className="fs-18 fw-700">Sorting Email Addresses</h4>
              <p className="fs-17">If you have a list of email addresses that you want to sort by domain name, you can reverse the entire list, sort them, and then reverse them back so that they are readable.</p>

              <h4 className="fs-18 fw-700">DNA Sequences</h4>
              <p className="fs-17">Doing molecular biology work and need to arrange DNA sequences? Have both strands written in 5'-3' order and need to reverse one? Then look no further!</p>

              <h4 className="fs-18 fw-700">Reverse This Text</h4>
              <p className="fs-17">You'll have to reverse this one to decipher it.... resrever txet siht htiw deifsitas %001 t'nera uoy fi eetnaraug kcab yenom a reffo eW.</p>

              <h4 className="fs-18 fw-700">French Text</h4>
              <p className="fs-17">Reverse the French text such as “texte inversé” into “éservni etxet” using this reverse text inverter.</p>

              <h4 className="fs-18 fw-700">Copy and Paste</h4>
              <p className="fs-17">Copy and paste your reverse text in word documents, Google Docs, or wherever you want.</p>

            </div>
          </div>
          <div className="col-md-3">
            <button type="button" onClick={Bookmark} class="mb-3 w-100 d-block theme-btn-nobg btn ">Bookmark this Tool</button>
          </div>
        </div>
      </Container>

      <script type="application/ld+json">
        {`{
            "@context": "https://schema.org/",
          "@type": "WebSite",
          "name": "Invisible Text",
          "url": "https://invisibletext.co/",
          "potentialAction": {
            "@type": "SearchAction",
          "target": "https://invisibletext.co/search/{search_term_string}",
          "query-input": "required name=search_term_string"
            }
          }`}
      </script>

      <script type="application/ld+json">
        {`{
          "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Invisible Text",
        "url": "https://invisibletext.co/",
        "logo": ""
        }`}
      </script>

    </div>
  );
};

export default ReverseText;
