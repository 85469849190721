import React, { useState } from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import MetaTitleDesc from '../MetaTitleDesc'
import { toast } from 'react-toastify';

const MediumMathematicalSpace = () => {
  const [inputValue, setInputValue] = useState(' ');

  const handleCopy = () => {
    navigator.clipboard.writeText(inputValue);
    toast.success("Medium Mathematical Space Copied");
  };

  const Bookmark = () => {
    alert('Press Ctrl+D to bookmark this page.');
  }
  return (
    <div className="notranslate" translate="no">
      <MetaTitleDesc Translate="no" MetaTitle="medium mathematical space (U+205F) Character Copy and Paste" MetaDesc="medium mathematical space (U+205F) Character Copy and Paste" />
      <Container fluid>
        <div className="row">
          <div className="col-md-9">
            <div className='mb-4'>
              <p className="fs-17">
                The invisible character medium mathematical space (U+205F) is a unique typographic tool that is specifically designed for mathematical expressions and formulas. This article will discuss the applications, insertion methods, and best practices for using the medium mathematical space in your projects.
              </p>
            </div>
            <div className="text-center">
              <h4 className="fs-18 fw-700">Copy Medium Mathematical Space</h4>
              <p className="fs-17">You can copy Medium Mathematical Space here and use it wherever you like</p>
              <input hidden type="text" value={inputValue} />
              <Button variant="secondary" onClick={handleCopy} className='mb-3 fs-18 theme-btn border-0' id="boton3"
                value="Copiar">
                Click Here to Copy Medium Mathematical Space (ㅤ)
              </Button>
            </div>
            <Row className="justify-content-center my-3">
              <Col md="8">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead className='table-dark'>
                      <tr>
                        <th>Unicode</th>
                        <th>Unicode Names</th>
                        <th>Copy</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>U+205F</td>
                        <td>Medium Mathematical Space</td>
                        <td>[ ]</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </div>
          <div className="col-md-3">
            <button onClick={Bookmark} class="mb-3 w-100 d-block theme-btn-nobg btn ">Bookmark this Tool</button>
          </div>
        </div>

      </Container>
    </div>
  );
};

export default MediumMathematicalSpace;
