import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Home from './Pages/Home'
import ES from './Pages/ES'
import RU from './Pages/RU'
import DE from './Pages/DE'
import About from './Pages/About'
import Space from './Pages/Space'
import NoBreakSpace from './Pages/NoBreakSpace'
import EnQuad from './Pages/EnQuad'
import EmQuad from './Pages/EmQuad'
import EnSpace from './Pages/EnSpace'
import EmSpace from './Pages/EmSpace'
import ThreePerEmSpace from './Pages/ThreePerEmSpace'
import FourPerEmSpace from './Pages/FourPerEmSpace'
import SixPerEmSpace from './Pages/SixPerEmSpace'
import FigureSpace from './Pages/FigureSpace'
import PunctuationSpace from './Pages/PunctuationSpace'
import ThinSpace from './Pages/ThinSpace'
import HairSpace from './Pages/HairSpace'
import LineSeparator from './Pages/LineSeparator'
import MediumMathematicalSpace from './Pages/MediumMathematicalSpace'
import IdeographicSpace from './Pages/IdeographicSpace'
import ReverseText from './Pages/ReverseText'
import PrivacyPolicy from './Pages/PrivacyPolicy'

const Routers = () => {

  return (
    <Routes basename="/">
        <Route path="/" element={<Home />} />
        <Route path="/reverse-text" element={<ReverseText />} />
        <Route path="/es" element={<ES />} />
        <Route path="/de" element={<DE />} />
        <Route path="/ru" element={<RU />} />
        <Route path="/home" element={<Navigate to="/" />} />
        <Route path="/space" element={<Space />} />
        <Route path="/no-break-space" element={<NoBreakSpace />} />
        <Route path="/en-quad" element={<EnQuad />} />
        <Route path="/em-quad" element={<EmQuad />} />
        <Route path="/en-space" element={<EnSpace />} />
        <Route path="/em-space" element={<EmSpace />} />
        <Route path="/three-per-em-space" element={<ThreePerEmSpace />} />
        <Route path="/four-per-em-space" element={<FourPerEmSpace />} />
        <Route path="/six-per-em-space" element={<SixPerEmSpace />} />
        <Route path="/figure-space" element={<FigureSpace />} />
        <Route path="/punctuation-space" element={<PunctuationSpace />} />
        <Route path="/thin-space" element={<ThinSpace />} />
        <Route path="/hair-space" element={<HairSpace />} />
        <Route path="/line-separator" element={<LineSeparator />} />
        <Route path="/medium-mathematical-space" element={<MediumMathematicalSpace />} />
        <Route path="/ideographic-space" element={<IdeographicSpace />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    </Routes>
  )
}

export default Routers